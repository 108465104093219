import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams(); // Extract the token from the URL
  const navigate = useNavigate();


  console.log('Token:', token);

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('Les mots de passe ne correspondent pas.');
      return;
    }

    try {
      const response = await axios.post(`/api/user/reset-password`, {
        token,
        newPassword
      });

      setMessage('Mot de passe réinitialisé avec succès.');
      setTimeout(() => {
        navigate('/login');
      }, 2000); // Navigate to login after 2 seconds
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.response?.data?.message || 'Une erreur s\'est produite. Veuillez réessayer.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Réinitialiser le mot de passe</h1>
      <div style={{ marginBottom: '20px' }}>
        <InputText
          placeholder="Nouveau mot de passe"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <InputText
          placeholder="Confirmer le mot de passe"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      {message && <p>{message}</p>}
      <Button label="Réinitialiser le mot de passe" icon="pi pi-refresh" onClick={handleResetPassword} />
    </div>
  );
};

export default ResetPassword;
