import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/style.css';
import { useUser } from '../Context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

const UsersComponent = () => {
    const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const { tryRefreshToken } = useUser();
    const [globalFilter, setGlobalFilter] = useState(null);

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    return handleApiRequest(method, url, data); // Retry the request with new token
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/users/all');
            setUsers(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des utilisateurs.");
        }
    }, [handleApiRequest]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleDeleteClick = (userId) => {
        setOpenDeleteDialog(true);
        setUserIdToDelete(userId);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDelete = async () => {
        try {
            await handleApiRequest('delete', `/api/admin/users/delete/${userIdToDelete}`);
            fetchUsers();
        } catch (err) {
            setError("Une erreur s'est produite lors de la suppression de l'utilisateur.");
        }
        setOpenDeleteDialog(false);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-trash" className="p-button-danger" onClick={() => handleDeleteClick(rowData.id)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left" style={{ marginLeft: 'auto' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className='adminencart'>
            <h2>Gestion des Utilisateurs</h2>
            <hr />
            {error && <Message severity="error" text={error} />}
            <DataTable value={users} paginator rows={10} header={header} globalFilter={globalFilter} dataKey="id" responsiveLayout="scroll">
                <Column field="username" header="Nom d'utilisateur" sortable></Column>
                <Column field="email" header="Email" sortable></Column>
                <Column field="isActive" header="Actif" sortable body={(rowData) => (rowData.isActive ? 'Oui' : 'Non')}></Column>
                <Column field="roles" header="Rôles" sortable body={(rowData) => (rowData.roles.includes('admin') ? 'Admin' : 'User')}></Column>
                <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>

            {/* Delete Confirmation Dialog */}
            <Dialog visible={openDeleteDialog} onHide={handleCloseDeleteDialog} header="Attention" modal footer={
                <div>
                    <Button label="Non" icon="pi pi-times" onClick={handleCloseDeleteDialog} className="p-button-text" />
                    <Button label="Oui" icon="pi pi-check" onClick={handleDelete} className="p-button-text" />
                </div>
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                    {userIdToDelete && <span>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default UsersComponent;
