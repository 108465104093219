import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/style.css';
import { useUser } from '../Context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { FileUpload } from 'primereact/fileupload';

const RecipesComponent = () => {
    const [error, setError] = useState('');
    const [recipes, setRecipes] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);
    const [openQrCodeDialog, setOpenQrCodeDialog] = useState(false);
    const [recipeIdToDelete, setRecipeIdToDelete] = useState(null);
    const [recipeIdToUploadImage, setRecipeIdToUploadImage] = useState(null);
    const [recipeIdForQrCode, setRecipeIdForQrCode] = useState(null);
    const [recipeQrCodeToDisplay, setRecipeQrCodeToDisplay] = useState(null);
    const [recipeImageToDisplay, setRecipeImageToDisplay] = useState(null);
    const [newRecipe, setNewRecipe] = useState({ nom: '' });
    const { tryRefreshToken } = useUser();
    const [globalFilter, setGlobalFilter] = useState(null);
    const navigate = useNavigate();

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        console.log(`Making API request: ${method} ${url}`);
        if (data && Object.keys(data).length > 0) {
            console.log('Request data:', data);
        }

        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            console.log('API response:', response.data);
            return response;
        } catch (err) {
            console.log('API request error:', err);
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    console.log('Retrying API request with new token');
                    return handleApiRequest(method, url, data); // Retry the request with new token
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchRecipes = useCallback(async () => {
        console.log('Fetching recipes');
        try {
            const response = await handleApiRequest('get', '/api/admin/recipe/all');
            setRecipes(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des recettes.");
        }
    }, [handleApiRequest]);

    
    

    useEffect(() => {
        fetchRecipes();
    }, [fetchRecipes]);

    const handleManageIngredientsClick = (recipeId) => {
        console.log(`Navigating to edit ingredients for recipe ${recipeId}`);
        navigate(`/admin/recipes-edit-ingredients/${recipeId}`);
    };

    const handleManageTagsClick = (recipeId) => {
        console.log(`Navigating to edit tags for recipe ${recipeId}`);
        navigate(`/admin/recipes-edit-tags/${recipeId}`);
    };

    const handleManageStepsClick = (recipeId) => {
        console.log(`Navigating to edit steps for recipe ${recipeId}`);
        navigate(`/admin/recipes-edit-steps/${recipeId}`);
    };

    const handleDeleteClick = (recipeId) => {
        console.log(`Opening delete dialog for recipe ${recipeId}`);
        setOpenDeleteDialog(true);
        setRecipeIdToDelete(recipeId);
    };

    const handleCloseDeleteDialog = () => {
        console.log('Closing delete dialog');
        setOpenDeleteDialog(false);
    };

    const handleDelete = async () => {
        console.log(`Deleting recipe ${recipeIdToDelete}`);
        try {
            await handleApiRequest('delete', `/api/admin/recipe/delete/${recipeIdToDelete}`);
            fetchRecipes();
            handleCloseDeleteDialog();
        } catch (err) {
            setError("Une erreur s'est produite lors de la suppression de la recette.");
            handleCloseDeleteDialog();
        }
    };

    const handleCreateRecipe = async () => {
        console.log('Creating new recipe:', newRecipe);
        try {
            await handleApiRequest('post', '/api/admin/recipe/create', newRecipe);
            fetchRecipes();
            setOpenCreateDialog(false);
            setNewRecipe({ nom: '' });
        } catch (err) {
            setError("Une erreur s'est produite lors de la création de la recette.");
        }
    };

    const handleOpenImageUploadDialog = (recipeId, imagePath) => {
        console.log(`Opening image upload dialog for recipe ${recipeId}`);
        setRecipeIdToUploadImage(recipeId);
    
        if (imagePath) {
            setRecipeImageToDisplay(`${process.env.REACT_APP_API_BASE_URL}/api/uploads/${imagePath}`); // Utiliser une URL dynamique
        } else {
            setRecipeImageToDisplay(null); // Pas d'image disponible
        }
    
        setOpenImageUploadDialog(true);
    };
    
    

    const handleImageUpload = async (event) => {
        const file = event.files[0];
        const formData = new FormData();
        formData.append('image', file); // Assurez-vous que le nom 'image' correspond à celui attendu par multer

        console.log(`Uploading image for recipe ${recipeIdToUploadImage}`, file);

        try {
            const response = await axios.post(`/api/admin/recipe/upload-image/${recipeIdToUploadImage}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-auth-token': localStorage.getItem('jwt')
                }
            });
            console.log('Server response:', response.data); // Ajoutez cette ligne pour déboguer la réponse du serveur
            if (response.data.message === 'Image uploaded successfully') {
                console.log('Image uploaded successfully:', response.data);
                fetchRecipes();
                setOpenImageUploadDialog(false);
            } else {
                setError("Une erreur s'est produite lors de l'upload de l'image.");
            }
        } catch (err) {
            console.log('Error uploading image:', err);
            setError("Une erreur s'est produite lors de l'upload de l'image.");
        }
    };

    const handleImageDelete = async (recipeId) => {
        console.log(`Deleting image for recipe ${recipeId}`);
        try {
            const response = await handleApiRequest('delete', `/api/admin/recipe/delete-image/${recipeId}`);
            if (response.data.message === 'Image deleted successfully') {
                console.log('Image deleted successfully');
                fetchRecipes();
                setOpenImageUploadDialog(false); // Fermer la boîte de dialogue après suppression
            } else {
                setError("Une erreur s'est produite lors de la suppression de l'image.");
            }
        } catch (err) {
            console.log('Error deleting image:', err);
            setError("Une erreur s'est produite lors de la suppression de l'image.");
        }
    };
    ///////////////////////////////////////Image Upload Dialog///////////////////////////////////////

    ///////////////////////////////////////QR Code Dialog///////////////////////////////////////
    const handleOpenQrCodeDialog = (recipeId, qrCodePath) => {
        console.log(`Opening QR code dialog for recipe ${recipeId}`);
        setRecipeIdForQrCode(recipeId);
    
        if (qrCodePath) {
            setRecipeQrCodeToDisplay(`${process.env.REACT_APP_API_BASE_URL}/api/qrcode/${qrCodePath}`); // Utiliser une URL dynamique
        } else {
            setRecipeQrCodeToDisplay(null); // Pas de QR code disponible
        }
    
        setOpenQrCodeDialog(true);
    };
    
    
    
    
    

    const handleGenerateQrCode = async () => {
        console.log(`Generating QR code for recipe ${recipeIdForQrCode}`);
        try {
            const response = await handleApiRequest('post', `/api/admin/qrcode/generate-qrcode/${recipeIdForQrCode}`);
            if (response.data.message === 'QR code generated and saved successfully') {
                console.log('QR code generated successfully');
                setRecipeQrCodeToDisplay(response.data.qrCodeFileName); // Set the new QR code path
                const updatedRecipes = recipes.map(recipe => {
                    if (recipe.id === recipeIdForQrCode) {
                        return { ...recipe, qrCode: response.data.qrCodeFileName };
                    }
                    return recipe;
                });
                setRecipes(updatedRecipes); // Update the recipes state
            } else {
                setError("Une erreur s'est produite lors de la génération du QR code.");
            }
        } catch (err) {
            console.log('Error generating QR code:', err);
            setError("Une erreur s'est produite lors de la génération du QR code.");
        }
    };
    
    const handleDeleteQrCode = async () => {
        console.log(`Deleting QR code for recipe ${recipeIdForQrCode}`);
        try {
            const response = await handleApiRequest('delete', `/api/admin/qrcode/delete-qrcode/${recipeIdForQrCode}`);
            if (response.data.message === 'QR code deleted successfully') {
                console.log('QR code deleted successfully');
                setRecipeQrCodeToDisplay(null); // Remove the QR code path
                const updatedRecipes = recipes.map(recipe => {
                    if (recipe.id === recipeIdForQrCode) {
                        return { ...recipe, qrCode: null };
                    }
                    return recipe;
                });
                setRecipes(updatedRecipes); // Update the recipes state
                setOpenQrCodeDialog(false); // Close the dialog after deletion
            } else {
                setError("Une erreur s'est produite lors de la suppression du QR code.");
            }
        } catch (err) {
            console.log('Error deleting QR code:', err);
            setError("Une erreur s'est produite lors de la suppression du QR code.");
        }
    };
    
    

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-cart-arrow-down" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleManageIngredientsClick(rowData.id)} />
                <Button icon="pi pi-tag" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleManageTagsClick(rowData.id)} />
                <Button icon="pi pi-list" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleManageStepsClick(rowData.id)} />
                <Button icon="pi pi-image" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleOpenImageUploadDialog(rowData.id, rowData.image)} />
                <Button icon="pi pi-qrcode" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleOpenQrCodeDialog(rowData.id, rowData.qrCode)} />
                <Button icon="pi pi-trash" className="p-button-danger" style={{ marginLeft: "2px" }} onClick={() => handleDeleteClick(rowData.id)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <Button label="Ajouter Recette" icon="pi pi-plus" className="p-button-success" onClick={() => setOpenCreateDialog(true)} />
            <span className="p-input-icon-left" style={{ marginLeft: '10px' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className='adminencart'>
            <h2>Gestion des recettes</h2>
            <hr></hr>
            {error && <div className="error">{error}</div>}
            <DataTable value={recipes} paginator rows={10} header={header} globalFilter={globalFilter} dataKey="id" responsiveLayout="scroll">
                <Column field="id" header="ID" sortable></Column>
                <Column field="nom" header="Nom" sortable></Column>
                <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>

            {/* -----------Delete Confirmation Dialog----------- */}
            <Dialog visible={openDeleteDialog} onHide={handleCloseDeleteDialog} header="Attention" modal footer={
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={handleCloseDeleteDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={handleDelete} />
                </React.Fragment>
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                    {recipeIdToDelete && <span>Êtes-vous sûr de vouloir supprimer cette recette ?</span>}
                </div>
            </Dialog>
            {/* -----------Delete Confirmation Dialog----------- */}

            {/*  -----------Create Recipe Dialog------------ */}
            <Dialog visible={openCreateDialog} onHide={() => setOpenCreateDialog(false)} header="Ajouter Nouvelle Recette" modal className="p-fluid">
                <div className="field">
                    <label htmlFor="nom">Nom</label>
                    <InputText id="nom" value={newRecipe.nom} onChange={(e) => setNewRecipe({ ...newRecipe, nom: e.target.value })} required autoFocus />
                </div>
                <div className="">
                    <Button label="Annuler" icon="pi pi-times" onClick={() => setOpenCreateDialog(false)} />
                    <Button label="Créer" icon="pi pi-check" onClick={handleCreateRecipe} />
                </div>
            </Dialog>
            {/*  -----------Create Recipe Dialog------------ */}

            {/* -----------Image Upload Dialog----------- */}
            <Dialog visible={openImageUploadDialog} onHide={() => setOpenImageUploadDialog(false)} header="Upload Image" modal className="p-fluid">
                {recipeImageToDisplay ? (
                    <>
                        <div className="image-preview">
                            <img src={recipeImageToDisplay} alt="Recipe" style={{ width: '100%', marginBottom: '10px' }} />
                        </div>
                        <Button label="Supprimer Image" icon="pi pi-trash" className="p-button-danger" onClick={() => handleImageDelete(recipeIdToUploadImage)} />
                    </>
                ) : (
                    <FileUpload name="image" customUpload uploadHandler={handleImageUpload} accept="image/*" maxFileSize={1000000} />
                )}
                <div className="">
                    <Button label="Annuler" icon="pi pi-times" onClick={() => setOpenImageUploadDialog(false)} />
                </div>
            </Dialog>
            {/* -----------Image Upload Dialog----------- */}


            {/* -----------QR Code Dialog----------- */}
            <Dialog visible={openQrCodeDialog} onHide={() => setOpenQrCodeDialog(false)} header="QR Code" modal className="p-fluid">
                {recipeQrCodeToDisplay ? (
                    <div className="image-preview">
                        <img src={recipeQrCodeToDisplay} alt="QR Code" style={{ width: '100%', marginBottom: '10px' }} />
                    </div>
                ) : (
                    <p>Aucun QR Code disponible pour cette recette.</p>
                )}
                {!recipeQrCodeToDisplay && (
                    <Button label="Générer QR Code" icon="pi pi-qrcode" className="p-button-secondary" onClick={handleGenerateQrCode} />
                )}
                {recipeQrCodeToDisplay && (
                    <>
                        <Button label="Supprimer QR Code" icon="pi pi-trash" className="p-button-danger" onClick={handleDeleteQrCode} />
                        <a href={recipeQrCodeToDisplay} download>
                            <Button label="Télécharger QR Code" icon="pi pi-download" className="p-button-secondary" />
                        </a>
                    </>
                )}
                <div className="">
                    <Button label="Annuler" icon="pi pi-times" onClick={() => setOpenQrCodeDialog(false)} />
                </div>
            </Dialog>
            {/* -----------QR Code Dialog----------- */}








        </div>
    );
};

export default RecipesComponent;
