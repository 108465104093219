import React, { useState } from 'react';
import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Button,
  Typography,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const Cart = () => {
  const [cartItems, setCartItems] = useState([
    { id: 1, name: 'Produit 1', image: '/path/to/image1.jpg', quantity: 1, price: 10.99 },
    { id: 2, name: 'Produit 2', image: '/path/to/image2.jpg', quantity: 2, price: 20.49 },
    // ... autres produits
  ]);

  const handleRemoveItem = (id) => {
    const updatedCartItems = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCartItems);
  };

  const handleQuantityChange = (id, newQuantity) => {
    const updatedCartItems = cartItems.map(item => {
      if (item.id === id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const totalPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Mon Panier
      </Typography>
      <List>
        {cartItems.map(item => (
          <ListItem key={item.id}>
            <ListItemAvatar>
              <Avatar src={item.image} alt={item.name} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Link to={`/product/${item.id}`} style={{ textDecoration: 'none' }}>
                  {item.name}
                </Link>
              }
              secondary={`Prix: ${item.price}€`}
            />
            <TextField
              type="number"
              value={item.quantity}
              onChange={(e) => handleQuantityChange(item.id, e.target.value)}
              style={{ marginRight: '20px', width: '50px' }}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => handleRemoveItem(item.id)}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Typography variant="h6">Total: {totalPrice.toFixed(2)}€</Typography>
      <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
        Payer
      </Button>
    </Container>
  );
};

export default Cart;
