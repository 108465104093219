import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/style.css';
import { useUser } from '../Context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const ProductsComponent = () => {
    const [error, setError] = useState('');
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [currentProduct, setCurrentProduct] = useState({ id: '', nom: '', price: '', description: '', tag: [] });
    const [newProduct, setNewProduct] = useState({ nom: '', price: '', description: '', tag: [] });
    const [productIdToUploadImage, setProductIdToUploadImage] = useState(null);
    const [productImageToDisplay, setProductImageToDisplay] = useState(null);
    const { tryRefreshToken } = useUser();
    const [globalFilter, setGlobalFilter] = useState(null);
    const navigate = useNavigate();

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    return handleApiRequest(method, url, data); // Retry the request with new token
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchProducts = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/product/all');
            setProducts(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des produits.");
        }
    }, [handleApiRequest]);

    const fetchTags = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/tag/get/diet');
            setTags(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des tags.");
        }
    }, [handleApiRequest]);

    useEffect(() => {
        fetchProducts();
        fetchTags();
    }, [fetchProducts, fetchTags]);

    const handleEditClick = (product) => {
        setCurrentProduct({ ...product, tag: product.tag || [] });
        setOpenEditDialog(true);
    };

    const handleCloseEdit = () => {
        setOpenEditDialog(false);
    };

    const handleSaveEdit = async () => {
        try {
            await handleApiRequest('put', `/api/admin/product/edit/${currentProduct.id}`, {
                nom: currentProduct.nom,
                price: currentProduct.price,
                description: currentProduct.description,
                isActive: currentProduct.isActive,
                tag: currentProduct.tag
            });
            fetchProducts();
            handleCloseEdit();
        } catch (err) {
            setError("Une erreur s'est produite lors de la mise à jour du produit.");
            handleCloseEdit();
        }
    };

    const handleDeleteClick = (productId) => {
        setOpenDeleteDialog(true);
        setProductIdToDelete(productId);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDelete = async () => {
        try {
            await handleApiRequest('delete', `/api/admin/product/delete/${productIdToDelete}`);
            fetchProducts();
            handleCloseDeleteDialog();
        } catch (err) {
            setError("Une erreur s'est produite lors de la suppression du produit.");
            handleCloseDeleteDialog();
        }
    };

    const handleCreateProduct = async () => {
        try {
            await handleApiRequest('post', '/api/admin/product/create', newProduct);
            fetchProducts();
            setOpenCreateDialog(false);
            setNewProduct({ nom: '', price: '', description: '', tag: [] });
        } catch (err) {
            setError("Une erreur s'est produite lors de la création du produit.");
        }
    };

    const handleOpenImageUploadDialog = async (productId, imagePath) => {
        console.log(`Opening image upload dialog for product ${productId}`);
        setProductIdToUploadImage(productId);
        
        if (imagePath) {
            try {
                setProductImageToDisplay(imagePath); // Use image ID directly
            } catch (err) {
                console.log('Error fetching image:', err);
                setProductImageToDisplay(null);
            }
        } else {
            setProductImageToDisplay(null);
        }

        setOpenImageUploadDialog(true);
    };

    const handleImageUpload = async (event) => {
        const file = event.files[0];
        const formData = new FormData();
        formData.append('image', file); // Assurez-vous que le nom 'image' correspond à celui attendu par multer

        console.log(`Uploading image for product ${productIdToUploadImage}`, file);

        try {
            const response = await axios.post(`/api/admin/product/upload-image/${productIdToUploadImage}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-auth-token': localStorage.getItem('jwt')
                }
            });
            console.log('Server response:', response.data); // Ajoutez cette ligne pour déboguer la réponse du serveur
            if (response.data.message === 'Image uploaded successfully') {
                console.log('Image uploaded successfully:', response.data);
                fetchProducts();
                setOpenImageUploadDialog(false);
            } else {
                setError("Une erreur s'est produite lors de l'upload de l'image.");
            }
        } catch (err) {
            console.log('Error uploading image:', err);
            setError("Une erreur s'est produite lors de l'upload de l'image.");
        }
    };

    const handleImageDelete = async (productId) => {
        console.log(`Deleting image for product ${productId}`);
        try {
            const response = await handleApiRequest('delete', `/api/admin/product/delete-image/${productId}`);
            if (response.data.message === 'Image deleted successfully') {
                console.log('Image deleted successfully');
                fetchProducts();
            } else {
                setError("Une erreur s'est produite lors de la suppression de l'image.");
            }
        } catch (err) {
            console.log('Error deleting image:', err);
            setError("Une erreur s'est produite lors de la suppression de l'image.");
        }
    };

    const handleNavigateToProduct = (productId) => {
        navigate(`/product/${productId}`);
    };

    const statusBodyTemplate = (rowData) => {
        return rowData.isActive ? (
            <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
            <CancelIcon style={{ color: 'red' }} />
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-pencil" className="p-button-primary" style={{ marginRight: "2px" }} onClick={() => handleEditClick(rowData)} />
                <Button icon="pi pi-image" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleOpenImageUploadDialog(rowData.id, rowData.image)} />
                <Button icon="pi pi-globe" className="p-button-secondary" style={{ marginRight: "2px" }} onClick={() => handleNavigateToProduct(rowData.id)} />
                <Button icon="pi pi-trash" className="p-button-danger" style={{ marginLeft: "2px" }} onClick={() => handleDeleteClick(rowData.id)} />
            </div>
        );
    };

    const header = (
        <div className="table-header">
            <Button label="Ajouter Produit" icon="pi pi-plus" className="p-button-success" onClick={() => setOpenCreateDialog(true)} />
            <span className="p-input-icon-left" style={{ marginLeft: '10px' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className='adminencart'>
            <h2>Gestion des produits</h2>
            <hr></hr>
            {error && <div className="error">{error}</div>}
            <DataTable value={products} paginator rows={10} header={header} globalFilter={globalFilter} dataKey="id" responsiveLayout="scroll">
                <Column field="nom" header="Nom" sortable></Column>
                <Column field="description" header="Description" sortable></Column>
                <Column field="price" header="Prix" sortable></Column>
                <Column header="Actif" body={statusBodyTemplate} sortable></Column>
                <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>

            {/* -----------Edit Dialog----------- */}
            <Dialog visible={openEditDialog} onHide={handleCloseEdit} header="Edit Product" modal className="p-fluid">
                <div className="field">
                    <label htmlFor="nom">Nom</label>
                    <InputText id="nom" value={currentProduct.nom} onChange={(e) => setCurrentProduct({ ...currentProduct, nom: e.target.value })} required autoFocus />
                </div>
                <div className="field">
                    <label htmlFor="description">Description</label>
                    <InputText id="description" value={currentProduct.description} onChange={(e) => setCurrentProduct({ ...currentProduct, description: e.target.value })} required />
                </div>
                <div className="field">
                    <label htmlFor="price">Prix</label>
                    <InputText id="price" value={currentProduct.price} onChange={(e) => setCurrentProduct({ ...currentProduct, price: e.target.value })} required />
                </div>
                <div className="field">
                    <label htmlFor="tag">Tag</label>
                    <Dropdown id="tag" value={currentProduct.tag} options={tags.map(tag => ({ label: tag.nom, value: tag._id }))} onChange={(e) => setCurrentProduct({ ...currentProduct, tag: e.value })} multiple />
                </div>
                <div className="field-checkbox">
                    <Checkbox inputId="isActive" checked={currentProduct.isActive} onChange={(e) => setCurrentProduct({ ...currentProduct, isActive: e.checked })} />
                    <label htmlFor="isActive">Active</label>
                </div>
                <div className="dialog-footer">
                    <Button label="Cancel" icon="pi pi-times" onClick={handleCloseEdit} />
                    <Button label="Save" icon="pi pi-check" onClick={handleSaveEdit} />
                </div>
            </Dialog>
            {/* -----------Edit Dialog----------- */}

            {/* -----------Delete Confirmation Dialog----------- */}
            <Dialog visible={openDeleteDialog} onHide={handleCloseDeleteDialog} header="Attention" modal footer={
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={handleCloseDeleteDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={handleDelete} />
                </React.Fragment>
            }>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                    {productIdToDelete && <span>Êtes-vous sûr de vouloir supprimer ce produit ?</span>}
                </div>
            </Dialog>
            {/* -----------Delete Confirmation Dialog----------- */}

            {/*  -----------Create Product Dialog------------ */}
            <Dialog visible={openCreateDialog} onHide={() => setOpenCreateDialog(false)} header="Add New Product" modal className="p-fluid">
                <div className="field">
                    <label htmlFor="nom">Nom</label>
                    <InputText id="nom" value={newProduct.nom} onChange={(e) => setNewProduct({ ...newProduct, nom: e.target.value })} required autoFocus />
                </div>
                <div className="field">
                    <label htmlFor="description">Description</label>
                    <InputText id="description" value={newProduct.description} onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })} required />
                </div>
                <div className="field">
                    <label htmlFor="price">Prix</label>
                    <InputText id="price" value={newProduct.price} onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })} required />
                </div>
                <div className="field">
                    <label htmlFor="tag">Tag</label>
                    <Dropdown id="tag" value={newProduct.tag} options={tags.map(tag => ({ label: tag.nom, value: tag._id }))} onChange={(e) => setNewProduct({ ...newProduct, tag: e.value })} multiple />
                </div>
                <div className="dialog-footer">
                    <Button label="Cancel" icon="pi pi-times" onClick={() => setOpenCreateDialog(false)} />
                    <Button label="Create" icon="pi pi-check" onClick={handleCreateProduct} />
                </div>
            </Dialog>
            {/**  -----------Create Product Dialog------------ */}

            {/* -----------Image Upload Dialog----------- */}
            <Dialog visible={openImageUploadDialog} onHide={() => setOpenImageUploadDialog(false)} header="Upload Image" modal className="p-fluid">
                {productImageToDisplay && (
                    <div className="image-preview">
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/api/uploads/${productImageToDisplay}`} alt="Product" style={{ width: '100%', marginBottom: '10px' }} />
                        <Button icon="pi pi-trash" className="p-button-danger" style={{ marginLeft: "2px" }} onClick={() => handleImageDelete(productIdToUploadImage)} />
                    </div>
                )}
                <FileUpload name="image" customUpload uploadHandler={handleImageUpload} accept="image/*" maxFileSize={1000000} />
                <div className="">
                    <Button label="Annuler" icon="pi pi-times" onClick={() => setOpenImageUploadDialog(false)} />
                </div>
            </Dialog>
            {/* -----------Image Upload Dialog----------- */}
        </div>
    );
};

export default ProductsComponent;
