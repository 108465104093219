import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './Firebase';
import { doc, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { Card, CardMedia, CardContent, Typography, Chip, Container, Grid } from '@mui/material';

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    const fetchProductAndRecipe = async () => {
      // Fetch product
      const productRef = doc(db, 'products', productId);
      const productDoc = await getDoc(productRef);
      if (productDoc.exists()) {
        setProduct({ ...productDoc.data(), id: productDoc.id });
      } else {
        console.error('Product not found');
      }

      // Fetch recipe
      const q = query(collection(db, 'recipes'), where('productId', '==', productId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const recipeData = querySnapshot.docs[0].data();
        setRecipe(recipeData);
      }
    };

    fetchProductAndRecipe();
  }, [productId]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              alt={product.name}
              height="400"
              image={product.image}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="div">
                {product.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {product.description}
              </Typography>
              <Typography variant="h6">
                Price: ${product.price}
              </Typography>
              <div>
                {product.tags.map((tag, index) => (
                  <Chip label={tag} key={index} style={{ margin: '4px' }} />
                ))}
              </div>
              {recipe && (
                <>
                  <Typography variant="h5" style={{ marginTop: '20px' }}>
                    Recipe
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Ingredients:</strong> {recipe.ingredients}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Servings:</strong> {recipe.servings}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Steps:</strong> {recipe.steps}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductPage;
