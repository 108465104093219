import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, storage } from './Firebase';
import {
  updateDoc,
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  getDocs,
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import {
  TextField,
  Button,
  Paper,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Chip from '@mui/material/Chip';

const ProductSettingsPage = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [ingredientQuantity, setIngredientQuantity] = useState("");  // Ajouté
  const [recipe, setRecipe] = useState({
    steps: [''],
    servings: 1,
    ingredients: [],
  });

  useEffect(() => {
    const fetchRecipeData = async () => {
      const recipeRef = doc(db, 'recipes', productId);
      const recipeSnap = await getDoc(recipeRef);
      if (recipeSnap.exists()) {
        setRecipe(recipeSnap.data());
      }
    };
    fetchRecipeData();
  }, [productId]);

  
  useEffect(() => {
    const fetchProductData = async () => {
      const docRef = doc(db, 'products', productId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProductData(docSnap.data());
      }
    };
    fetchProductData();
  }, [productId]);

  useEffect(() => {
    const fetchIngredients = async () => {
      const ingredientsCollection = collection(db, 'ingredients');
      const ingredientsSnapshot = await getDocs(query(ingredientsCollection));
      const ingredientsList = ingredientsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(ingredientsList);  // Log the data here
      setIngredients(ingredientsList);
    };
    fetchIngredients();
  }, []);
  

  const handleImageUpload = async () => {
    if (newImage) {
      const timestamp = Date.now();
      const uniqueImageName = `${timestamp}-${newImage.name}`;
      const storageRef = ref(storage, 'products/' + uniqueImageName);
      const uploadTask = uploadBytesResumable(storageRef, newImage);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Optional: handle progress updates.
        },
        (error) => {
          // Handle unsuccessful uploads
          console.error('Upload failed', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setProductData({ ...productData, image: downloadURL });
          const productRef = doc(db, 'products', productId);
          await updateDoc(productRef, { image: downloadURL });
        }
      );
    }
  };

  const handleSave = async () => {
    await handleImageUpload();
    const docRef = doc(db, 'products', productId);
    await setDoc(docRef, productData);
  };

  const addIngredientToRecipe = () => {
    if (selectedIngredient && ingredientQuantity) {
        const updatedIngredients = [
            ...recipe.ingredients,
            { ingredient: selectedIngredient.id, quantity: ingredientQuantity }
        ];
        setRecipe({ ...recipe, ingredients: updatedIngredients });
        // Réinitialiser les valeurs
        setSelectedIngredient(null);
        setIngredientQuantity("");
    }
};
 


  const handleRecipeSave = async () => {
    const recipeRef = doc(db, 'recipes', productId);
    await setDoc(recipeRef, recipe);
  };

  if (!productData) return null;


  return (
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Paper style={{ padding: 16 }}>
                <img src={productData.image} alt={productData.name} style={{ width: '100%', height: 'auto' }} />
                <input type="file" onChange={(e) => setNewImage(e.target.files[0])} />
                <TextField
                    value={productData.name}
                    onChange={(e) => setProductData({ ...productData, name: e.target.value })}
                    label="Name"
                    fullWidth
                />
                <TextField
                    value={productData.price}
                    onChange={(e) => setProductData({ ...productData, price: e.target.value })}
                    label="Price"
                    fullWidth
                />
               
                <Button variant="outlined" color="primary" onClick={handleSave} style={{ marginTop: 16 }}>
                    Save
                </Button>
            </Paper>
        </Grid>
        <Grid item xs={12}>
            <Paper style={{ padding: 16 }}>
                <Typography variant="h6">Add Recipe</Typography>
                <TextField
                    type="number"
                    value={recipe.servings}
                    onChange={(e) => setRecipe({ ...recipe, servings: e.target.value })}
                    label="Servings"
                />
                {recipe.steps.map((step, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                        <TextField
                            value={step}
                            onChange={(e) => {
                                const newSteps = [...recipe.steps];
                                newSteps[index] = e.target.value;
                                setRecipe({ ...recipe, steps: newSteps });
                            }}
                            label={`Step ${index + 1}`}
                            fullWidth
                        />
                        <IconButton
                            onClick={() => {
                                const newSteps = [...recipe.steps];
                                newSteps.splice(index, 1);
                                setRecipe({ ...recipe, steps: newSteps });
                            }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </div>
                ))}
                <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => setRecipe({ ...recipe, steps: [...recipe.steps, ''] })}
                >
                    Add Step
                </Button>
                <Autocomplete
                    value={selectedIngredient}
                    options={ingredients}
                    getOptionLabel={(option) => option.nom}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Ingredient" variant="outlined" />
                    )}
                    onChange={(event, value) => {
                        setSelectedIngredient(value);
                    }}
                />
                <TextField
                    value={ingredientQuantity}
                    onChange={(e) => setIngredientQuantity(e.target.value)}
                    label="Quantité"
                    fullWidth
                />
                <Button
                    onClick={addIngredientToRecipe}  // Appeler votre fonction ici
                >
                    Ajouter l'ingrédient
                </Button>
                {recipe.ingredients.map((ingredientData, index) => {
                    const ingredient = ingredients.find(ing => ing.id === ingredientData.ingredient);
                    return (
                        <div key={index}>
                            <Chip
                                label={ingredient ? `${ingredient.nom} : ${ingredientData.quantity} ${ingredient.unite}` : ''}
                                onDelete={() => {
                                    const updatedIngredients = [...recipe.ingredients];
                                    updatedIngredients.splice(index, 1);
                                    setRecipe({ ...recipe, ingredients: updatedIngredients });
                                }}
                            />
                        </div>
                    );
                })}
                <Button variant="outlined" color="primary" onClick={handleRecipeSave} style={{ marginTop: 16 }}>
                    Save Recipe
                </Button>
            </Paper>
        </Grid>
    </Grid>
);



};

export default ProductSettingsPage;

