import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const AccountActivated = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <i className="pi pi-check" style={{ fontSize: '4em', color: 'green' }}></i>
      <h1>Compte activé avec succès !</h1>
      <p>Merci d'avoir activé votre compte. Vous pouvez maintenant vous connecter.</p>
      <Button label="Se connecter" icon="pi pi-sign-in" className="p-button-success" onClick={handleLoginClick} />
    </div>
  );
};

export default AccountActivated;
