import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "./Context/CartContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  TextField,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const Panier = () => {
  const { cart, removeFromCart, updateItemQuantity, deleteFromCart } = useContext(CartContext);
  const [productDetails, setProductDetails] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const db = getFirestore();
      const detailsArray = await Promise.all(
        cart.map(async (item) => {
          const productDoc = doc(db, "products", item.productId);
          const productData = await getDoc(productDoc);
          if (productData.exists()) {
            return {
              productId: item.productId,
              name: productData.data().name,
              image: productData.data().image,
              price: productData.data().price,
              quantity: item.quantity,
            };
          } else {
            console.log(`Product not found for ID: ${item.productId}`);
            return null;
          }
        })
      );
      const validDetails = detailsArray.filter((details) => details !== null);
      setProductDetails(validDetails);
    };

    fetchProductDetails();
  }, [cart, removeFromCart]);

  const handleQuantityChange = (productId, event) => {
    const newQuantity = event.target.value;
    if (newQuantity > 0) {
      updateItemQuantity(productId, newQuantity);
    } else {
      removeFromCart(productId);
    }
  };

  const handleDelete = (productId) => {
    setSelectedProduct(productId);
    setOpenDialog(true);
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      deleteFromCart(selectedProduct);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Votre panier</h1>
      {productDetails.length ? (
        <List>
        {productDetails.map((product, index) => (
          <ListItem key={index}>
            <img src={product.image} alt={product.name} style={{ width: '100px', marginRight: '20px' }} />
            <ListItemText
              primary={product.name}
            />
            <TextField
              type="number"
              value={product.quantity}
              onChange={(event) => handleQuantityChange(product.productId, event)}
              inputProps={{ min: 1 }}
              variant="outlined"
              size="small"
              style={{ marginRight: '20px' }}
            />
            <ListItemText 
                      secondary={`Prix: ${product.price * product.quantity} €`} 
                    />
                    
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(product.productId)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1">Votre panier est vide</Typography>
          <Button variant="outlined" color="primary" component={Link} to="/page">Continuer vos achats</Button>
        </div>
      )}
      {productDetails.length > 0 && (
        <Button variant="contained" color="primary" style={{ marginTop: '20px', display: 'block', marginLeft: 'auto' }}>Payer</Button>
      )}

      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
      >
        <DialogTitle>Confirmez la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir retirer cet article de votre panier ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Panier;
