import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../Context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const IngredientsComponent = () => {
    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [ingredientIdToDelete, setIngredientIdToDelete] = useState(null);
    const [newIngredient, setNewIngredient] = useState({ nom: '', unite: '' });
    const { tryRefreshToken } = useUser();
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {
        const axiosInterceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    try {
                        const newToken = await tryRefreshToken();
                        if (newToken) {
                            localStorage.setItem('jwt', newToken);
                            axios.defaults.headers.common['x-auth-token'] = newToken;
                            originalRequest.headers['x-auth-token'] = newToken;
                            return axios(originalRequest);
                        }
                    } catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }
                return Promise.reject(error);
            }
        );

        fetchIngredients();

        return () => {
            axios.interceptors.response.eject(axiosInterceptor);
        };
    }, [tryRefreshToken]);

    const fetchIngredients = async () => {
        try {
            const response = await axios.get('/api/admin/ingredient/all', {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            setIngredients(response.data);
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des ingrédients.", error);
        }
    };

    const handleDeleteClick = (ingredientId) => {
        setOpenDeleteDialog(true);
        setIngredientIdToDelete(ingredientId);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleCloseUploadDialog = () => {
        setOpenUploadDialog(false);
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete(`/api/admin/ingredient/delete/${ingredientIdToDelete}`, {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            if (response.status === 200) {
                fetchIngredients(); // Refresh the list after deleting an ingredient
                handleCloseDeleteDialog();
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la suppression de l'ingrédient.", error);
            handleCloseDeleteDialog();
        }
    };

    const handleCreateIngredient = async () => {
        try {
            const response = await axios.post('/api/admin/ingredient/create', newIngredient, {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            if (response.status === 201) {
                fetchIngredients(); // Refresh the list after creating an ingredient
                handleCloseCreateDialog();
                setNewIngredient({ nom: '', unite: '' });
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de la création de l'ingrédient.", error);
        }
    };

    const handleUpload = async ({ files }) => {
        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('/api/admin/ingredient/upload-csv', formData, {
                headers: {
                    'x-auth-token': localStorage.getItem('jwt'),
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                fetchIngredients(); // Refresh the list after uploading a CSV
                handleCloseUploadDialog(); // Close the dialog after successful upload
            }
        } catch (error) {
            console.error("Une erreur s'est produite lors de l'importation du fichier CSV.", error);
        }
    };

    const header = (
        <div className="table-header">
            <Button label="Ajouter Ingrédient" icon="pi pi-plus" className="p-button-success" onClick={() => setOpenCreateDialog(true)} />
            <Button label="Importer CSV" icon="pi pi-upload" className="p-button-secondary" onClick={() => setOpenUploadDialog(true)} style={{ marginLeft: '10px' }} />
            <span className="p-input-icon-left" style={{ marginLeft: '10px' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className="datatable-crud-demo">
            <div className="card">
                <DataTable value={ingredients}
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    selection={selectedIngredients}
                    onSelectionChange={(e) => setSelectedIngredients(e.value)}
                    dataKey="_id"
                    responsiveLayout="scroll"
                >
                    <Column field="nom" header="Nom" sortable></Column>
                    <Column field="unite" header="Unité" sortable></Column>
                    <Column body={(rowData) => (
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={() => handleDeleteClick(rowData._id)} />
                    )}></Column>
                </DataTable>
            </div>

            <Dialog visible={openCreateDialog} style={{ width: '450px' }} header="Ajouter un nouvel ingrédient" modal className="p-fluid" footer={
                <React.Fragment>
                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={handleCloseCreateDialog} />
                    <Button label="Créer" icon="pi pi-check" className="p-button-text" onClick={handleCreateIngredient} />
                </React.Fragment>
            } onHide={handleCloseCreateDialog}>
                <div className="field">
                    <label htmlFor="nom">Nom</label>
                    <InputText id="nom" value={newIngredient.nom} onChange={(e) => setNewIngredient({ ...newIngredient, nom: e.target.value })} required autoFocus />
                </div>
                <div className="field">
                    <label htmlFor="unite">Unité</label>
                    <InputText id="unite" value={newIngredient.unite} onChange={(e) => setNewIngredient({ ...newIngredient, unite: e.target.value })} required />
                </div>
            </Dialog>

            <Dialog visible={openDeleteDialog} style={{ width: '450px' }} header="Confirmation" modal footer={
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={handleCloseDeleteDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={handleDelete} />
                </React.Fragment>
            } onHide={handleCloseDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                    {ingredientIdToDelete && <span>Êtes-vous sûr de vouloir supprimer cet ingrédient ?</span>}
                </div>
            </Dialog>

            <Dialog visible={openUploadDialog} style={{ width: '450px' }} header="Importer un fichier CSV" modal className="p-fluid" footer={
                <React.Fragment>
                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={handleCloseUploadDialog} />
                </React.Fragment>
            } onHide={handleCloseUploadDialog}>
                <FileUpload name="file" accept=".csv" customUpload uploadHandler={handleUpload} mode="advanced" chooseLabel="Choisir" uploadLabel="Importer" />
            </Dialog>
        </div>
    );
};

export default IngredientsComponent;
