import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import '../css/style.css';
import { useUser } from '../Context/UserContext';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const DashboardComponent = () => {
  const [error, setError] = useState('');
  const [data, setData] = useState({
    productCount: 0,
    ingredientsCount: 0,
    usersCount: 0,
    tagCount: 0,
    receipeCount: 0,
  });
  const { tryRefreshToken } = useUser();

  const handleApiRequest = useCallback(async (method, url) => {
    let token = localStorage.getItem('jwt');
    try {
      const config = {
        headers: { 'x-auth-token': token },
        method: method,
        url: url,
      };
      const response = await axios(config);
      return response;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        const newToken = await tryRefreshToken();
        if (newToken) {
          localStorage.setItem('jwt', newToken);
          return handleApiRequest(method, url); // Retry the request with new token
        } else {
          setError("Votre session a expiré. Veuillez vous reconnecter.");
        }
      } else {
        throw err;
      }
    }
  }, [tryRefreshToken]);

  const fetchData = useCallback(async () => {
    try {
      const [productRes, ingredientsRes, usersRes, tagRes, receipeRes] = await Promise.all([
        handleApiRequest('get', '/api/admin/dashboard/product'),
        handleApiRequest('get', '/api/admin/dashboard/ingredients'),
        handleApiRequest('get', '/api/admin/dashboard/users'),
        handleApiRequest('get', '/api/admin/dashboard/tag'),
        handleApiRequest('get', '/api/admin/dashboard/receipe'),
      ]);

      setData({
        productCount: productRes.data.productCount,
        ingredientsCount: ingredientsRes.data.ingredientsCount,
        usersCount: usersRes.data.usersCount,
        tagCount: tagRes.data.tagCount,
        receipeCount: receipeRes.data.receipeCount,
      });
    } catch (err) {
      setError("Une erreur s'est produite lors de la récupération des données.");
    }
  }, [handleApiRequest]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createOption = (label, count) => ({
    datasets: [
      {
        data: [count, 100 - count],
        backgroundColor: ['#FF6384', '#E0E0E0'],
        hoverBackgroundColor: ['#FF6384', '#E0E0E0'],
        borderWidth: 1,
        cutout: '50%',
        rotation: -90,
        circumference: 180,
      },
    ],
    labels: [label, ''],
  });

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      {error && <div className="error">{error}</div>}
      <div className='fond'>
        <div className='admincard'>
          <Doughnut data={createOption('Products', data.productCount)} options={options} />
          <div className='counter'>{data.productCount}</div>
        </div>
        <div className='admincard'>
          <Doughnut data={createOption('Ingredients', data.ingredientsCount)} options={options} />
          <div className='counter'>{data.ingredientsCount}</div>
        </div>
        <div className='admincard'>
          <Doughnut data={createOption('Users', data.usersCount)} options={options} />
          <div className='counter'>{data.usersCount}</div>
        </div>
      </div>
      <div className='fond'>
        <div className='admincard'>
          <Doughnut data={createOption('Tags', data.tagCount)} options={options} />
          <div className='counter'>{data.tagCount}</div>
        </div>
        <div className='admincard'>
          <Doughnut data={createOption('Recipes', data.receipeCount)} options={options} />
          <div className='counter'>{data.receipeCount}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
