//import React from 'react';
//import { Navigate } from 'react-router-dom';
//import { useUser } from '../Context/UserContext'; // Assurez-vous que le chemin est correct

const ProtectedRoute = ({ children }) => {
  //const { roles } = useUser();

  //if (!roles || !roles.includes('admin')) {
    // Rediriger vers la page de connexion si l'utilisateur n'a pas le rôle 'admin'
  //  return <Navigate to="/login" />;
  //}

  return children;
};

export default ProtectedRoute;
