import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
//import crypto from 'crypto-browserify';
import { Button, TextField, Typography, Container, Alert, Box } from '@mui/material';
import '../css/style.css';


const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    //const hashPassword = (password) => {
    //    return crypto.createHash('sha256').update(password).digest('hex');
    //};

    const handleSubmit = async (event) => {
        event.preventDefault();



        if (password !== confirmPassword) {
            setError("Les mots de passe ne correspondent pas.");
            return;
        }

        //const hashedPassword = hashPassword(password);

        console.log("user", username);
        console.log("email", email);
        console.log("password", password);
        //console.log("hashedPassword", hashedPassword);

        try {
            await axios.post('/auth/register', {
                username,
                email,
                //password: hashedPassword
                password
            });
            navigate('/login');
        } catch (err) {
            if (err.response) {
                setError(err.response.data.message);
            } else {
                setError("Erreur de réseau ou problème de serveur");
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <div className='encart1'>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" align="center">S'inscrire</Typography>
            </Box>
            {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Nom d'utilisateur"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Adresse Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de Passe"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmer le Mot de Passe"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    S'inscrire
                </Button>
            </form>
            </div>
        </Container>
    );
};

export default Register;
