import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../css/style.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';

const PlayComposant = () => {
    const [error, setError] = useState('');
    const [url, setUrl] = useState('');
    const [recipes, setRecipes] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                setError("Votre session a expiré. Veuillez vous reconnecter.");
            } else {
                throw err;
            }
        }
    }, []);

    const fetchRecipes = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/recipeplay/all');
            setRecipes(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des recettes.");
        }
    }, [handleApiRequest]);

    const handleFetchRecipe = async () => {
        try {
            console.log('Sending POST request to fetch recipe with URL:', url);
            await handleApiRequest('post', '/api/admin/recipeplay/fetch-recipe', { url });
            console.log('POST request successful, fetching recipes...');
            fetchRecipes();
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération de la recette.");
        }
    };

    useEffect(() => {
        fetchRecipes();
    }, [fetchRecipes]);

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left" style={{ marginLeft: 'auto' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className='adminencart'>
            <h2>PlayGround</h2>
            <hr />
            {error && <Message severity="error" text={error} />}
            
            <div className="p-grid p-align-center p-justify-between">
                <div className="p-col-9">
                    <InputText 
                        value={url} 
                        onChange={(e) => setUrl(e.target.value)} 
                        placeholder="Entrez l'URL de la recette" 
                        style={{ width: '100%' }} 
                    />
                </div>
                <div className="p-col-3">
                    <Button 
                        label="Récupérer" 
                        icon="pi pi-download" 
                        onClick={handleFetchRecipe} 
                        className="p-button-success" 
                        style={{ width: '100%' }}
                    />
                </div>
            </div>

            <DataTable value={recipes} paginator rows={10} header={header} globalFilter={globalFilter} dataKey="_id" responsiveLayout="scroll">
                <Column field="nom" header="Nom" sortable></Column>
                <Column field="description" header="Description" sortable></Column>
                <Column field="nombre de personnes" header="Nombre de Personnes" sortable></Column>
                <Column field="listingredient" header="Ingrédients" body={(rowData) => rowData.listingredient.map(ing => `${ing.nomIngredient} (${ing.quantity} ${ing.mesureQuantity})`).join(', ')} sortable></Column>
                <Column field="step" header="Étapes" body={(rowData) => rowData.step.join(', ')} sortable></Column>
            </DataTable>
        </div>
    );
};

export default PlayComposant;
