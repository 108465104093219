import React, { useEffect, useState, useContext } from 'react';
import { db } from './Firebase';
import { collection, getDocs } from 'firebase/firestore';
import { CartContext } from './Context/CartContext';
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link } from 'react-router-dom';

const Page = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useContext(CartContext); 
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [uniqueTags, setUniqueTags] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const productCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productCollection);
      const productList = productSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      // Obtention des tags uniques
      const tags = new Set();
      productList.forEach(product => product.tags.forEach(tag => tags.add(tag)));
      setUniqueTags(Array.from(tags));

      setProducts(productList.filter(product => !selectedTag || product.tags.includes(selectedTag)));
    };

    fetchProducts();
  }, [selectedTag]);

  const handleAddToCart = (product) => {
    addToCart({ productId: product.id, quantity: 1 }); 
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <TextField
          label="Rechercher"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <IconButton color="primary" aria-label="Filtrer les produits" onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
        <Menu
          id="filter-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => { setSelectedTag(''); handleClose(); }}>Tous les produits</MenuItem>
          {uniqueTags.map((tag, index) => (
            <MenuItem key={index} onClick={() => { setSelectedTag(tag); handleClose(); }}>{tag}</MenuItem>
          ))}
        </Menu>
      </div>
      <Grid container spacing={4} style={{ padding: '20px' }}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.id}>
            <Link to={`/product/${product.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>  {/* Ajoutez le lien ici */}
              <Card>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={product.image}
                    alt={product.name}
                  />
                  <CardContent>
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="body2">{product.description}</Typography>
                    <Typography variant="subtitle1">{product.price} €</Typography>
                  </CardContent>
                </CardActionArea>
                <Button color="primary" onClick={() => handleAddToCart(product)}>
                  Ajouter au panier
                </Button>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Page;
