import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext'; // Chemin corrigé
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const EditRecipeStepsComponent = () => {
    const [error, setError] = useState('');
    const [steps, setSteps] = useState([]);
    const [newStep, setNewStep] = useState('');
    const { id } = useParams(); // This is the recipe ID
    const navigate = useNavigate();
    const { tryRefreshToken } = useUser();

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    return handleApiRequest(method, url, data);
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchRecipeSteps = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', `/api/admin/recipe/edit/${id}`);
            setSteps(response.data.step || []);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des étapes.");
        }
    }, [handleApiRequest, id]);

    useEffect(() => {
        fetchRecipeSteps();
    }, [fetchRecipeSteps]);

    const handleAddStep = () => {
        if (newStep.trim() !== '') {
            setSteps([...steps, newStep]);
            setNewStep('');
        }
    };

    const handleRemoveStep = (index) => {
        setSteps(steps.filter((_, i) => i !== index));
    };

    const handleSaveSteps = async () => {
        try {
            await handleApiRequest('put', `/api/admin/recipe/edit/${id}`, {
                step: steps
            });
            navigate('/admin/recipes');
        } catch (err) {
            setError("Une erreur s'est produite lors de la mise à jour des étapes.");
        }
    };

    const stepBodyTemplate = (rowData, column) => {
        const index = steps.indexOf(rowData);

        switch (column.field) {
            case 'step':
                return <span>{rowData}</span>;
            case 'action':
                return (
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={() => handleRemoveStep(index)} />
                );
            default:
                return null;
        }
    };

    return (
        <div className='adminencart'>
            <h2>Gérer les Étapes</h2>
            <hr></hr>
            {error && <div className="error">{error}</div>}
            <div className="p-field">
                <label htmlFor="newStep">Nouvelle Étape</label>
                <InputText id="newStep" value={newStep} onChange={(e) => setNewStep(e.target.value)} />
                <Button label="Ajouter Étape" icon="pi pi-plus" onClick={handleAddStep} />
            </div>
            <DataTable value={steps} paginator rows={10} dataKey="id">
                <Column field="step" header="Étape" body={stepBodyTemplate}></Column>
                <Column field="action" header="Action" body={stepBodyTemplate}></Column>
            </DataTable>
            <div className="dialog-footer">
                <Button label="Annuler" icon="pi pi-times" onClick={() => navigate('/admin/recipes')} />
                <Button label="Sauvegarder" icon="pi pi-check" onClick={handleSaveSteps} />
            </div>
        </div>
    );
};

export default EditRecipeStepsComponent;
