import { db } from './Firebase';
import { useEffect, useState, useContext } from 'react';
import { query, where, collection, getDocs } from 'firebase/firestore';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Container,
  IconButton,
} from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import { CartContext } from './Context/CartContext';
import './css/Home.css';
import { Link } from 'react-router-dom';
import logo from './Assets/logo.png';

const Home = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productCollection = collection(db, 'products');
        const q = query(productCollection, where("tags", "array-contains", "new"));
        const productSnapshot = await getDocs(q);
        const productList = productSnapshot.docs.map(doc => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        setProducts(productList);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    fetchProducts();
  }, []);


  
  
  
  

  const handleAddToCart = (product) => {
    // Supposons que votre fonction addToCart accepte un objet avec id et quantity comme propriétés      <img src={img1} alt="principale" style={{ width: '100%' }} />

    addToCart({ productId: product.id, quantity: 1 });
  };
  
  return (
    <div>

      <div className="container" slyle={{marginTop: '10%'}}>
        <div className="row mt-5">
          <div className="col-12 mt-5">
            <div className="banner">
              
              <h1>ONLYPAN <img src={logo} alt="logo" style={{ width: '5%',marginLeft: '-30px', marginTop: '-50px'}} /></h1>
              <p><i>La meilleur manière de s'organiser</i></p>
            </div>
          </div>
        </div>
      </div>


      <Container className='container' style={{backgroundColor: 'white', width: '97%', marginTop: '10%', padding:'5%'}}>
        

        <div className='row p-5'>
          <div className='col-4'>
          </div>
          <div className='col-4'>
          </div>
          <div className='col-4'>
          </div> 
        </div>
        <Box sx={{ borderBottom: '1px solid #000', width: '60px', margin: '0 auto 20px auto' }} />

        <Grid container spacing={4}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>



<Card className="cardHover" sx={{ position: 'relative', overflow: 'visible' }}>
  <CardActionArea>
    <CardMedia
      className="MuiCardMedia-root"
      component="img"
      height="auto"
      image={product.image}
      alt={product.name}
    />
  </CardActionArea>



  <Box 
    sx={{ 
      position: 'absolute', 
      top: 0, 
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',  // ajustez l'opacité comme désiré
      color: '#fff',
      opacity: 0,
      transition: 'opacity 0.3s',
      zIndex: 1,
    }} 
    className="descriptionContainer"
  >
    <Typography variant="h6" align="center" gutterBottom>{product.name}</Typography>
    <Typography variant="body2" align="center">{product.description}</Typography>
  </Box>

  <Box 
    sx={{ 
      position: 'absolute', 
      bottom: 16, 
      left: 16, 
      opacity: 0, 
      transition: 'opacity 0.3s',
      zIndex: 2,
    }} 
    className="iconContainer"
  >
        <Typography variant="body2" style={{ color: '#fff' }}>{product.price} €</Typography>

    
  </Box>
  <Box 
    sx={{ 
      position: 'absolute', 
      bottom: 16, 
      right: 16, 
      opacity: 0, 
      transition: 'opacity 0.3s',
      zIndex: 2, // Assurez-vous que les icônes apparaissent au-dessus de la description
    }} 
    className="iconContainer"
  >
    {/* Utilisez Link dans IconButton pour le routing en cliquant sur InfoIcon */}
    <IconButton aria-label="Info" color="inherit" component={Link} to={`/product/${product.id}`}>
      <InfoIcon style={{ color: '#fff' }} />
    </IconButton>
    
    <IconButton aria-label="Add to cart" color="inherit" onClick={() => handleAddToCart(product)}>
      <AddShoppingCartIcon style={{ color: '#fff' }} />
    </IconButton>
  </Box>
</Card>


<Card className="cardHover" sx={{ position: 'relative', overflow: 'visible' }}>
  <CardActionArea>
    <CardMedia
      className="MuiCardMedia-root"
      component="img"
      height="auto"
      image={product.image}
      alt={product.name}
    />
  </CardActionArea>

  <Box 
    sx={{ 
      position: 'absolute', 
      top: 0, 
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',  // ajustez l'opacité comme désiré
      color: '#fff',
      opacity: 0,
      transition: 'opacity 0.3s',
      zIndex: 1,
    }} 
    className="descriptionContainer"
  >
    <Typography variant="h6" align="center" gutterBottom>{product.name}</Typography>
    <Typography variant="body2" align="center">{product.description}</Typography>
  </Box>

  <Box 
    sx={{ 
      position: 'relative', 
      overflow: 'visible',
      borderRadius: '16px', // Ajouter des coins arrondis
      margin: '20px', // Ajouter de l'espace autour de la carte
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Ajouter un effet d'ombre
    }}
    className="iconContainer"
  >
        <Typography variant="body2" style={{ color: '#fff' }}>{product.price} €</Typography>

    
  </Box>
  <Box 
    sx={{ 
      position: 'absolute', 
      bottom: 16, 
      right: 16, 
      opacity: 0, 
      transition: 'opacity 0.3s',
      zIndex: 2, // Assurez-vous que les icônes apparaissent au-dessus de la description
    }} 
    className="iconContainer"
  >
    {/* Utilisez Link dans IconButton pour le routing en cliquant sur InfoIcon */}
    <IconButton aria-label="Info" color="inherit" component={Link} to={`/product/${product.id}`}>
      <InfoIcon style={{ color: '#fff' }} />
    </IconButton>
    
    <IconButton aria-label="Add to cart" color="inherit" onClick={() => handleAddToCart(product)}>
      <AddShoppingCartIcon style={{ color: '#fff' }} />
    </IconButton>
  </Box>
</Card>



            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
