// CartContext.js
import React, { createContext, useState, useEffect } from "react";
import { db } from "../Firebase";
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [userId, setUserId] = useState(null);  // Ajoutez ceci
    const navigate = useNavigate();  // Initialisez useNavigate


    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // L'utilisateur est connecté
                setUserId(user.uid);  // Mettez à jour userId ici
            } else {
                // L'utilisateur est déconnecté
                setUserId(null);  // Réinitialisez userId ici
            }
        });

        // Retournez une fonction de nettoyage pour vous désabonner de l'écouteur lorsque le composant est démonté
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchCart = async () => {
            if (userId) {
                const cartDoc = doc(db, "panier", userId);
                const cartData = await getDoc(cartDoc);
                if (cartData.exists()) {
                    setCart(cartData.data().items);
                } else {
                    await setDoc(cartDoc, { items: [] });  // Changez updateDoc en setDoc ici
                }
            }
        };

        fetchCart();
    }, [userId]);

    const addToCart = async (item) => {
        console.log('addToCart called with item:', item);  // Log pour le débogage
        if (userId) {
            const cartRef = doc(db, 'panier', userId);
            try {
                const cartSnap = await getDoc(cartRef);

                if (!cartSnap.exists()) {
                    console.log('Cart does not exist, creating new cart document');  // Log pour le débogage
                    await setDoc(cartRef, {
                        userId,
                        items: [{ ...item, quantity: 1 }]  // Initialise la quantité à 1
                    });
                } else {
                    console.log('Cart exists, updating cart document');  // Log pour le débogage
                    const existingItems = cartSnap.data().items;
                    const itemIndex = existingItems.findIndex(existingItem => existingItem.productId === item.productId);
                    if (itemIndex === -1) {
                        // L'item n'existe pas encore dans le panier, l'ajouter
                        existingItems.push({ ...item, quantity: 1 });  // Initialise la quantité à 1
                    } else {
                        // L'item existe déjà dans le panier, incrémenter la quantité
                        existingItems[itemIndex].quantity += 1;
                    }
                    await updateDoc(cartRef, { items: existingItems });
                }

                // Récupérer le panier mis à jour
                const updatedCartSnap = await getDoc(cartRef);
                const updatedCartData = updatedCartSnap.data();
                setCart(updatedCartData.items);
            } catch (error) {
                console.error('Error updating cart:', error);  // Gestion des erreurs
            }
        } else {
            console.warn('User is not logged in');  // Avertissement si l'utilisateur n'est pas connecté
            navigate('/login');  // Rediriger vers la page de connexion
        }
    };

    const removeFromCart = async (productId, quantity) => {
        if (userId) {
            const cartDoc = doc(db, "panier", userId);
            const cartData = await getDoc(cartDoc);
            const existingItems = cartData.data().items;
            const existingItemIndex = existingItems.findIndex(item => item.productId === productId);
            if (existingItemIndex > -1) {
                if (quantity >= existingItems[existingItemIndex].quantity) {
                    // Remove the item entirely if the removal quantity is greater or equal to the existing quantity
                    existingItems.splice(existingItemIndex, 1);
                } else {
                    // Otherwise, just subtract the removal quantity
                    existingItems[existingItemIndex].quantity -= quantity;
                }
                await updateDoc(cartDoc, { items: existingItems });
                setCart(existingItems);
            }
        }
    };

    const deleteFromCart = async (productId) => {
        if (userId) {
            const cartDoc = doc(db, "panier", userId);
            const cartData = await getDoc(cartDoc);
            const existingItems = cartData.data().items;
            const updatedItems = existingItems.filter(item => item.productId !== productId);
            await updateDoc(cartDoc, { items: updatedItems });
            setCart(updatedItems);
        }
    };

    const updateItemQuantity = async (productId, newQuantity) => {
        if (userId) {
            const numericalQuantity = Number(newQuantity);  // Convertir la quantité en nombre
            if (numericalQuantity <= 0 || isNaN(numericalQuantity)) {
                // Si la nouvelle quantité est <= 0 ou NaN, supprimez l'élément du panier
                await deleteFromCart(productId);
            } else {
                const cartDoc = doc(db, "panier", userId);
                const cartData = await getDoc(cartDoc);
                const existingItems = cartData.data().items;
                const existingItemIndex = existingItems.findIndex(item => item.productId === productId);
                if (existingItemIndex > -1) {
                    existingItems[existingItemIndex].quantity = numericalQuantity;  // Mettez à jour la quantité
                    await updateDoc(cartDoc, { items: existingItems });
                    setCart(existingItems);  // Mettez à jour l'état local
                }
            }
        }
    };
    

    

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, deleteFromCart, updateItemQuantity}}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
