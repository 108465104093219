import GoogleQR from './Assets/GoogleQR.webp';

const PrivacyPolicy = () => {
  return (
    <div className='text-center'>
      <img src={GoogleQR} alt="GoogleQR" height="40%" />
    </div>
  );
};

export default PrivacyPolicy;