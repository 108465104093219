import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import "primereact/resources/themes/lara-light-cyan/theme.css";


axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
//console.log("axios.defaults.baseURL indexJS", axios.defaults.baseURL);
//console.log("process.env.REACT_APP_API_BASE_URL indexJS", process.env.REACT_APP_API_BASE_URL);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
