import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const UserContext = createContext();

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export const UserProvider = ({ children }) => {
    const [_id, set_id] = useState(() => localStorage.getItem('_id') || null);
    const [user, setUser] = useState(() => localStorage.getItem('username') || null);
    const [email, setEmail] = useState(() => localStorage.getItem('email') || null);
    const [roles, setRoles] = useState(() => {
        const rolesInLocalStorage = localStorage.getItem('roles');
        try {
            return rolesInLocalStorage ? JSON.parse(rolesInLocalStorage) : null;
        } catch (e) {
            console.error('Error parsing roles:', e);
            return null;
        }
    });

    const clearUser = useCallback(() => {
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('role');
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
        set_id(null);
        setUser(null);
        setEmail(null);
        setRoles(null);
    }, []);

    const tryRefreshToken = useCallback(async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            clearUser();
            return null;
        }

        try {
            const response = await axios.post('/auth/refresh-token', { refreshToken });
            const { token } = response.data;
            localStorage.setItem('jwt', token);
            return token;
        } catch (error) {
            console.error('Error refreshing token:', error);
            clearUser();
            return null;
        }
    }, [clearUser]);

    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('jwt');
            if (!token) {
                await tryRefreshToken();
                return;
            }

            try {
                const response = await axios.get('/api/user/info', {
                    headers: { 'x-auth-token': token }
                });
                const userData = response.data;
                localStorage.setItem('userId', userData._id);
                localStorage.setItem('username', userData.username);
                localStorage.setItem('userEmail', userData.email);
                localStorage.setItem('roles', JSON.stringify(userData.roles));
                set_id(userData._id);
                setUser(userData.username);
                setEmail(userData.email);
                setRoles(userData.roles);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    await tryRefreshToken();
                } else {
                    console.error('Error fetching user info:', error);
                    clearUser();
                }
            }
        };

        fetchUser();
    }, [tryRefreshToken, clearUser]);

    return (
        <UserContext.Provider value={{ _id, user, email, roles, setUser, setEmail, setRoles, clearUser, tryRefreshToken }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
