import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../css/BoutiqueComponent.css";

const BoutiqueComponent = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    
    // Fetch products on mount
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                // Fetch data from your API
                const response = await axios.get('/api/product/all'); // Adjust to the correct API endpoint as needed
                setProducts(response.data); // Store the fetched products in state
            } catch (err) {
                console.error("Failed to fetch products:", err);
            }
        };
        fetchProducts();
    }, []);

    const handleProductClick = (productId) => {
        if (productId) {
            navigate(`/product/${productId}`);
        } else {
            console.error("Product ID is undefined");
        }
    };

    return (
        <div className='shop-container'>
            <h2>Our Products</h2>
            <div className="products-grid">
                {products.map((product) => (
                    <div 
                        key={product._id} 
                        className="product-card" 
                        onClick={() => handleProductClick(product.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <h3>{product.nom}</h3>
                        <img 
                            src={product.image ? `${process.env.REACT_APP_API_BASE_URL}/api/uploads/${product.image}` : 'path/to/default/image.png'} 
                            alt={product.nom} 
                            style={{ width: '100%', marginBottom: '10px' }} 
                        />
                        <div className="price">{product.price} €</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BoutiqueComponent;
