import React from 'react';
import './css/FooterComponent.css';

const FooterComponent = () => {
  return (
    <footer className="footer"> 
      All rights reserved. Copyright © 2024 Onlypan by Adrien, Arthur, Nicolas.
    </footer>
  );
};

export default FooterComponent;
