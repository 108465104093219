import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext'; // Chemin corrigé
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';

const EditRecipesTagComponents = () => {
    const [error, setError] = useState('');
    const [recipeTags, setRecipeTags] = useState([]);
    const [initialTags, setInitialTags] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const { id } = useParams(); // This is the recipe ID
    const navigate = useNavigate();
    const { tryRefreshToken } = useUser();

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    return handleApiRequest(method, url, data);
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchRecipeTags = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', `/api/admin/recipe/edit/${id}`);
            const existingTags = response.data.tag || [];
            const formattedTags = existingTags.map(tagId => {
                const tag = availableTags.find(t => t._id === tagId);
                return tag || { _id: tagId, nom: '' };
            });
            setRecipeTags(formattedTags);
            setInitialTags(formattedTags);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des tags.");
        }
    }, [handleApiRequest, id, availableTags]);

    const fetchAvailableTags = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/tag/all');
            setAvailableTags(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des tags disponibles.");
        }
    }, [handleApiRequest]);

    useEffect(() => {
        fetchAvailableTags();
    }, [fetchAvailableTags]);

    useEffect(() => {
        if (availableTags.length > 0) {
            fetchRecipeTags();
        }
    }, [fetchRecipeTags, availableTags]);

    const handleTagChange = (e) => {
        setRecipeTags(e.value);
    };

    const handleSaveTags = async () => {
        const initialTagIds = initialTags.map(tag => tag._id).sort();
        const currentTagIds = recipeTags.map(tag => tag._id).sort();
        if (JSON.stringify(initialTagIds) === JSON.stringify(currentTagIds)) {
            navigate('/admin/recipes');
            return;
        }
        
        try {
            await handleApiRequest('put', `/api/admin/recipe/edit/${id}`, {
                tag: recipeTags.map(tag => tag._id)
            });
            navigate('/admin/recipes');
        } catch (err) {
            setError("Une erreur s'est produite lors de la mise à jour des tags.");
        }
    };

    return (
        <div className='adminencart'>
            <h2>Gérer les Tags</h2>
            <hr></hr>
            {error && <div className="error">{error}</div>}
            <div className="p-field">
                <label htmlFor="tags">Tags</label>
                <MultiSelect
                    value={recipeTags}
                    options={availableTags}
                    onChange={handleTagChange}
                    optionLabel="nom"
                    placeholder="Sélectionnez des tags"
                    display="chip"
                    id="tags"
                />
            </div>
            <div className="dialog-footer">
                <Button label="Annuler" icon="pi pi-times" onClick={() => navigate('/admin/recipes')} />
                <Button label="Sauvegarder" icon="pi pi-check" onClick={handleSaveTags} />
            </div>
        </div>
    );
};

export default EditRecipesTagComponents;
