import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ProductDetailComponent = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`/api/product/${id}`);
                setProduct(response.data);
            } catch (err) {
                console.error("Failed to fetch product:", err);
            }
        };
        fetchProduct();
    }, [id]);

    if (!product) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>{product.nom}</h1>
            <img 
                src={product.image ? `${process.env.REACT_APP_API_BASE_URL}/api/uploads/${product.image}` : 'path/to/default/image.png'} 
                alt={product.nom}
                style={{ width: '100%' }}
            />
            <p>{product.description}</p>
            <div className="price">{product.price} €</div>
        </div>
    );
};

export default ProductDetailComponent;
