import React, { useRef, useContext } from 'react';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Link, useNavigate } from 'react-router-dom';
import logo from './Assets/logo_nom_couleur.png';
import './css/NavbarComponent.css';
import { CartContext } from './Context/CartContext';
import { useUser } from './Context/UserContext';

const NavbarComponent = () => {
    const { user, clearUser, roles } = useUser();
    const { cart } = useContext(CartContext);
    const navigate = useNavigate();
    const accountMenu = useRef(null);
    const mainMenu = useRef(null);

    const totalItems = cart.reduce((total, item) => total + item.quantity, 0);

    const handleLogout = () => {
        clearUser();
        navigate('/');
    };

    const accountItems = [
        user ? { label: 'Profile', icon: 'pi pi-fw pi-user', command: () => navigate('/profile') } : null,
        user ? { label: 'Déconnexion', icon: 'pi pi-fw pi-sign-out', command: handleLogout } : null,
        !user ? { label: 'Se connecter', icon: 'pi pi-fw pi-sign-in', command: () => navigate('/login') } : null,
        !user ? { label: 'S\'inscrire', icon: 'pi pi-fw pi-user-plus', command: () => navigate('/register') } : null
    ].filter(Boolean);

    const mainMenuItems = [
        { label: 'Boutique', icon: 'pi pi-fw pi-shopping-bag', command: () => navigate('/boutique') },
        { label: 'Page', icon: 'pi pi-fw pi-info-circle', command: () => navigate('/page') },
        roles && roles.includes('admin') && { label: 'Admin', icon: 'pi pi-fw pi-cog', command: () => navigate('/admin') }
    ].filter(Boolean);

    const start = (
        <Link to="/">
            <img src={logo} alt="Logo" height="40" className="mr-2" />
        </Link>
    );

    const end = (
        <div className="p-d-flex">
            <Button icon="pi pi-bars" className="p-button-rounded p-button-text p-mr-2" onClick={(e) => mainMenu.current.toggle(e)} />
            <Menu model={mainMenuItems} popup ref={mainMenu} />
            <Button icon="pi pi-shopping-cart" className="p-button-rounded p-button-text p-mr-2" badge={totalItems} />
            <Button icon="pi pi-user" className="p-button-rounded p-button-text" onClick={(e) => accountMenu.current.toggle(e)} />
            <Menu model={accountItems} popup ref={accountMenu} />
        </div>
    );

    return (
        <Menubar start={start} end={end} />
    );
}

export default NavbarComponent;
