import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../Context/UserContext';
import { Button, TextField, Typography, Container, Alert } from '@mui/material';
import '../css/style.css';

const Profile = () => {
    const { user, setUser } = useUser();
    const [displayName, setDisplayName] = useState(user?.username || '');
    const [error, setError] = useState('');

    console.log("user", user);

    useEffect(() => {
        // Cette fonction sera appelée lorsque le composant est monté
        // et chaque fois que l'utilisateur est mis à jour dans le contexte.
        setDisplayName(user.username || '');
    }, [user]);

    const handleSave = async () => {
        if (!displayName) {
            setError('Le nom d\'utilisateur ne peut pas être vide.');
            return;
        }

        try {
            const response = await axios.put('/api/user/update', {
                userId: user._id, // Assurez-vous que l'ID utilisateur est envoyé correctement
                username: displayName,
            });

            // Mettre à jour l'utilisateur dans le contexte si la réponse est réussie
            if (response.data.success) {
                setUser({ ...user, username: displayName });
                setError('');
                alert('Profil mis à jour avec succès.');
            } else {
                setError('Une erreur est survenue lors de la mise à jour du profil.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Erreur lors de la connexion au serveur.');
        }
    };

    return (
        <Container maxWidth="sm">
        <div className='encart1'>
            <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
                <Typography variant="h5" style={{ marginBottom: '20px' }}>
                    Personnaliser le profil
                </Typography>
                <Typography variant="body1" style={{ marginBottom: '20px' }}>
                    E-mail : {user?.email}
                </Typography>
                <TextField
                    fullWidth
                    label="Nom d'utilisateur"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    style={{ marginBottom: '20px' }}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    style={{ marginTop: '20px' }}
                >
                    Sauvegarder les modifications
                </Button>
            </div>
        </div>
        </Container>
    );
};

export default Profile;
