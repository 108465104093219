import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import '../css/Admin.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TagIcon from '@mui/icons-material/Tag';
import EggIcon from '@mui/icons-material/Egg';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';

const AdminLayout = () => {
    const location = useLocation();

    const getActiveClass = (path) => {
        return location.pathname.includes(path) ? 'css-bshv44-MuiButtonBase-root-MuiListItem-root2' : '';
    };

    return (
        <div style={{ display: 'flex'}}>
            <div className='adminmenu'>
                <List>
                    <ListItem className={getActiveClass('/admin/dashboard')} button component={Link} to="/admin/dashboard">
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="DashBoard" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/users')} button component={Link} to="/admin/users">
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Gérer les Utilisateurs" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/recipes')} button component={Link} to="/admin/recipes">
                        <ListItemIcon><MenuBookIcon /></ListItemIcon>
                        <ListItemText primary="Gérer les Recettes" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/products')} button component={Link} to="/admin/products">
                        <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                        <ListItemText primary="Gérer les Produit" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/tags')} button component={Link} to="/admin/tags">
                        <ListItemIcon><TagIcon /></ListItemIcon>
                        <ListItemText primary="Gérer les Tags" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/ingredients')} button component={Link} to="/admin/ingredients">
                        <ListItemIcon><EggIcon /></ListItemIcon>
                        <ListItemText primary="Gérer les ingredients" />
                    </ListItem>
                    <hr></hr>
                    <ListItem className={getActiveClass('/admin/play')} button component={Link} to="/admin/play">
                        <ListItemIcon><DataSaverOnIcon /></ListItemIcon>
                        <ListItemText primary="Playground" />
                    </ListItem>
                </List>
            </div>
            <div style={{ flex: 1, padding: '0px 20px 20px 20px' }}>
                <Outlet /> {/* Cela rendra le composant enfant correspondant à la route active */}
            </div>
        </div>
    );
};

export default AdminLayout;
