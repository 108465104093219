import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../Context/UserContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

const TagsComponent = () => {
    const [error, setError] = useState('');
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [tagIdToDelete, setTagIdToDelete] = useState(null);
    const [newTag, setNewTag] = useState({ nom: '', type: '' });
    const [selectedCategory, setSelectedCategory] = useState('');
    const { tryRefreshToken } = useUser();
    const [globalFilter, setGlobalFilter] = useState(null);

    useEffect(() => {
        const axiosInterceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    try {
                        const newToken = await tryRefreshToken();
                        if (newToken) {
                            localStorage.setItem('jwt', newToken);
                            axios.defaults.headers.common['x-auth-token'] = newToken;
                            originalRequest.headers['x-auth-token'] = newToken;
                            return axios(originalRequest);
                        }
                    } catch (refreshError) {
                        return Promise.reject(refreshError);
                    }
                }
                return Promise.reject(error);
            }
        );

        fetchTags();

        return () => {
            axios.interceptors.response.eject(axiosInterceptor);
        };
    }, [tryRefreshToken]);

    const fetchTags = async () => {
        try {
            const response = await axios.get('/api/admin/tag/all', {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            setTags(response.data);
        } catch (error) {
            setError("Une erreur s'est produite lors de la récupération des tags.");
        }
    };

    const handleDeleteClick = (tagId) => {
        setOpenDeleteDialog(true);
        setTagIdToDelete(tagId);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleDelete = async () => {
        try {
            const response = await axios.delete(`/api/admin/tag/delete/${tagIdToDelete}`, {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            if (response.status === 200) {
                fetchTags(); // Refresh the list after deleting a tag
                handleCloseDeleteDialog();
            }
        } catch (error) {
            setError("Une erreur s'est produite lors de la suppression du tag.");
            handleCloseDeleteDialog();
        }
    };

    const handleCreateTag = async () => {
        try {
            const response = await axios.post('/api/admin/tag/create', newTag, {
                headers: { 'x-auth-token': localStorage.getItem('jwt') }
            });
            if (response.status === 201) {
                fetchTags(); // Refresh the list after creating a tag
                handleCloseCreateDialog();
                setNewTag({ nom: '', type: '' });
            }
        } catch (error) {
            setError("Une erreur s'est produite lors de la création du tag.");
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.value);
    };

    const filteredTags = selectedCategory === '' ? tags : tags.filter(tag => tag.type === selectedCategory);

    const header = (
        <div className="table-header">
            <Button label="Ajouter Tag" icon="pi pi-plus" className="p-button-success" onClick={() => setOpenCreateDialog(true)} />
            <Dropdown 
                value={selectedCategory} 
                options={[{ label: 'Tous', value: '' }, ...Array.from(new Set(tags.map(tag => tag.type))).map(type => ({ label: type, value: type }))]}
                onChange={handleCategoryChange} 
                placeholder="Filtrer par type" 
                style={{ marginLeft: '10px' }} 
            />
            <span className="p-input-icon-left" style={{ marginLeft: '10px' }}>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher..." />
            </span>
        </div>
    );

    return (
        <div className='adminencart'>
            <h2>Gestion des Tags</h2>
            <hr />
            {error && <div className="error">{error}</div>}
            <div className="card">
                <DataTable value={filteredTags}
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    selection={selectedTags}
                    onSelectionChange={(e) => setSelectedTags(e.value)}
                    dataKey="_id"
                    responsiveLayout="scroll"
                >
                    <Column field="nom" header="Nom" sortable></Column>
                    <Column field="type" header="Type" sortable></Column>
                    <Column body={(rowData) => (
                        <Button icon="pi pi-trash" className="p-button-danger" onClick={() => handleDeleteClick(rowData._id)} />
                    )}></Column>
                </DataTable>
            </div>

            <Dialog visible={openCreateDialog} style={{ width: '450px' }} header="Ajouter un nouveau Tag" modal className="p-fluid" footer={
                <React.Fragment>
                    <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={handleCloseCreateDialog} />
                    <Button label="Créer" icon="pi pi-check" className="p-button-text" onClick={handleCreateTag} />
                </React.Fragment>
            } onHide={handleCloseCreateDialog}>
                <div className="field">
                    <label htmlFor="nom">Nom</label>
                    <InputText id="nom" value={newTag.nom} onChange={(e) => setNewTag({ ...newTag, nom: e.target.value })} required autoFocus />
                </div>
                <div className="field">
                    <label htmlFor="type">Type</label>
                    <InputText id="type" value={newTag.type} onChange={(e) => setNewTag({ ...newTag, type: e.target.value })} required />
                </div>
            </Dialog>

            <Dialog visible={openDeleteDialog} style={{ width: '450px' }} header="Attention" modal footer={
                <React.Fragment>
                    <Button label="Non" icon="pi pi-times" className="p-button-text" onClick={handleCloseDeleteDialog} />
                    <Button label="Oui" icon="pi pi-check" className="p-button-text" onClick={handleDelete} />
                </React.Fragment>
            } onHide={handleCloseDeleteDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem' }} />
                    {tagIdToDelete && <span>Êtes-vous sûr de vouloir supprimer ce tag ?</span>}
                </div>
            </Dialog>
        </div>
    );
};

export default TagsComponent;
