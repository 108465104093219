import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/UserContext'; // Chemin corrigé
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

const EditRecipesIngredientsComponents = () => {
    const [error, setError] = useState('');
    const [listIngredient, setListIngredient] = useState([]);
    const [availableIngredients, setAvailableIngredients] = useState([]);
    const { id } = useParams(); // This is the recipe ID
    const navigate = useNavigate();
    const { tryRefreshToken } = useUser();
    const [listeIngredientId, setListeIngredientId] = useState(null); // To store the listeIngredient ID

    const handleApiRequest = useCallback(async (method, url, data = {}) => {
        let token = localStorage.getItem('jwt');
        try {
            const config = {
                headers: { 'x-auth-token': token },
                method: method,
                url: url,
                ...(Object.keys(data).length && { data: data })
            };
            const response = await axios(config);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                const newToken = await tryRefreshToken();
                if (newToken) {
                    localStorage.setItem('jwt', newToken);
                    return handleApiRequest(method, url, data);
                } else {
                    setError("Votre session a expiré. Veuillez vous reconnecter.");
                }
            } else {
                throw err;
            }
        }
    }, [tryRefreshToken]);

    const fetchIngredients = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', `/api/admin/recipe/edit/${id}`);
            if (response.data.listeIngredient) {
                setListeIngredientId(response.data.listeIngredient); // Set the listeIngredient ID
                const listIngredientResponse = await handleApiRequest('get', `/api/admin/listingredient/edit/${response.data.listeIngredient}`);
                setListIngredient(Array.isArray(listIngredientResponse.data.ingredient) ? listIngredientResponse.data.ingredient : []);
            } else {
                setListIngredient([]);
            }
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des ingrédients.");
        }
    }, [handleApiRequest, id]);

    const fetchAvailableIngredients = useCallback(async () => {
        try {
            const response = await handleApiRequest('get', '/api/admin/ingredient/all');
            setAvailableIngredients(response.data);
        } catch (err) {
            setError("Une erreur s'est produite lors de la récupération des ingrédients disponibles.");
        }
    }, [handleApiRequest]);

    useEffect(() => {
        fetchIngredients();
        fetchAvailableIngredients();
    }, [fetchIngredients, fetchAvailableIngredients]);

    const handleIngredientChange = (index, field, value) => {
        const newListIngredient = [...listIngredient];
        newListIngredient[index][field] = value;
        setListIngredient(newListIngredient);
    };

    const handleAddIngredient = () => {
        setListIngredient([...listIngredient, { idIngredient: '', quantity: '', unit: '' }]);
    };

    const handleRemoveIngredient = (index) => {
        const newListIngredient = listIngredient.filter((_, i) => i !== index);
        setListIngredient(newListIngredient);
    };

    const handleSaveIngredients = async () => {
        if (!listeIngredientId) {
            setError("ID de la liste des ingrédients non trouvé.");
            return;
        }
        try {
            await handleApiRequest('put', `/api/admin/listingredient/edit/${listeIngredientId}`, {
                ingredient: listIngredient
            });
            navigate('/admin/recipes');
        } catch (err) {
            setError("Une erreur s'est produite lors de la mise à jour des ingrédients.");
        }
    };

    const ingredientBodyTemplate = (rowData, column) => {
        const index = listIngredient.indexOf(rowData);

        switch (column.field) {
            case 'idIngredient':
                return (
                    <Dropdown 
                        value={rowData.idIngredient}
                        options={availableIngredients.map(ing => ({ label: ing.nom, value: ing._id }))}
                        onChange={(e) => handleIngredientChange(index, 'idIngredient', e.value)}
                        placeholder="Sélectionnez un ingrédient"
                        required 
                    />
                );
            case 'quantity':
                return (
                    <InputText 
                        value={rowData.quantity}
                        onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                        placeholder="Quantité"
                        required 
                    />
                );
            case 'unit':
                const ingredient = availableIngredients.find(ing => ing._id === rowData.idIngredient);
                return ingredient ? ingredient.unite : '';
            case 'action':
                return (
                    <Button label="Supprimer" icon="pi pi-trash" className="p-button-danger" onClick={() => handleRemoveIngredient(index)} />
                );
            default:
                return null;
        }
    };

    return (
        <div className='adminencart'>
            <h2>Gérer les Ingrédients</h2>
            <hr></hr>
            {error && <div className="error">{error}</div>}
            <DataTable value={listIngredient} paginator rows={10} dataKey="id">
                <Column field="idIngredient" header="Ingrédient" body={ingredientBodyTemplate}></Column>
                <Column field="quantity" header="Quantité" body={ingredientBodyTemplate}></Column>
                <Column field="unit" header="Unité" body={ingredientBodyTemplate}></Column>
                <Column field="action" header="Action" body={ingredientBodyTemplate}></Column>
            </DataTable>
            <Button label="Ajouter Ingrédient" icon="pi pi-plus" onClick={handleAddIngredient} />
            <div className="dialog-footer">
                <Button label="Annuler" icon="pi pi-times" onClick={() => navigate('/admin/recipes')} />
                <Button label="Sauvegarder" icon="pi pi-check" onClick={handleSaveIngredients} />
            </div>
        </div>
    );
};

export default EditRecipesIngredientsComponents;
