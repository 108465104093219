import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavbarComponent from './NavbarComponent';
import FooterComponent from './FooterComponent';
import Home from './Home';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Cart from './Cart';
import Profile from './Auth/Profile';
import { UserProvider } from './Context/UserContext';
import CartProvider from './Context/CartContext';
import Page from './Page';
import AccountActivated from './account-activated';
import ResetPassword from './reset-password';
import RequestPasswordReset from './request-reset-password';
import Panier from './Panier';
import ProductPage from './ProductPage';
import ProductSettingsPage from './ProductSettingPage';
import AdminLayout from './Admin/AdminLayout';
import UsersComponent from './Admin/UsersComponent';
import RecipesComponent from './Admin/RecipesComponent';
import EditRecipesIngredientsComponents from './Admin/EditRecipes/EditRecipesIngredientsComponents';
import EditRecipesTagComponents from './Admin/EditRecipes/EditRecipesTagComponents';
import EditRecipesStepsComponent from './Admin/EditRecipes/EditRecipeStepsComponent';
import IngredientsComponent from './Admin/IngredientsComponent';
import ProductsComponent from './Admin/ProductsComponent';
import DashboardComponent from './Admin/DashboardComponent';
import PlayComponent from './Admin/PlayComponent';
import BoutiqueComponent from './Boutique/BoutiqueComponent';
import ProductDetailComponent from './Boutique/ProductDetailComponent';
import TagComponent from './Admin/TagsComponent';
import ProtectedRoute from './Auth/ProtectedRoute';
import { trackPageView } from './analytics';
import PrivacyPolicy from './privacy-policy';
import GoogleQR from './GoogleQR';



function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);
}

const PageTracker = ({ children }) => {
  usePageTracking();
  return <>{children}</>;
};

function App() {
  return (
    <UserProvider>
      <Router>
        <CartProvider>
          <header>
            <NavbarComponent />
          </header>
          <main>
            <PageTracker>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
                  <Route path="dashboard" element={<DashboardComponent />} />
                  <Route path="users" element={<UsersComponent />} />
                  <Route path="tags" element={<TagComponent />} />
                  <Route path="ingredients" element={<IngredientsComponent />} />
                  <Route path="products" element={<ProductsComponent />} />
                  <Route path="recipes" element={<RecipesComponent />} />
                  <Route path="recipes-edit-ingredients/:id" element={<EditRecipesIngredientsComponents />} />
                  <Route path="recipes-edit-tags/:id" element={<EditRecipesTagComponents />} />
                  <Route path="recipes-edit-steps/:id" element={<EditRecipesStepsComponent />} />
                  <Route path="play" element={<PlayComponent />} />
                </Route>      
                <Route path="/google-qr" element={<GoogleQR />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/request-password-reset" element={<RequestPasswordReset />} />
                <Route path="/account-activated" element={<AccountActivated />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/boutique" element={<BoutiqueComponent />} />
                <Route path="/product/:id" element={<ProductDetailComponent />} />
                <Route path="/page" element={<Page />} />
                <Route path="/product/:productId" element={<ProductPage />} /> 
                <Route path="/panier" element={<Panier />} />
                <Route path="/home" element={<Home />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/product-settings/:productId" element={<ProductSettingsPage />} />
              </Routes>
            </PageTracker>
          </main>
          <footer>
            <FooterComponent />
          </footer>
        </CartProvider>
      </Router>
    </UserProvider>
  );
}

export default App;
