import React, { useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, TextField, Typography, Container, Alert } from '@mui/material';
import { Box } from '@mui/system';
import '../css/style.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const lowerCaseEmail = email.toLowerCase();

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`/auth/login`, {
                email: lowerCaseEmail,
                password
            });

            console.log("Bien Essayé :)");

            const { token, refreshToken } = response.data;
            localStorage.setItem('jwt', token);
            localStorage.setItem('refreshToken', refreshToken);

            navigate('/');
            window.location.reload();
        } catch (err) {
            if (err.response) {
                setError(err.response.data.message);
            } else {
                setError("Erreur de réseau ou problème de serveur");
            }
        }
    };

    return (
        <Container maxWidth="sm">
            <div className='encart1'>
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" align="center">Se connecter</Typography>
                </Box>
                {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Adresse Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Mot de Passe"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Se connecter
                    </Button>
                    <Button
                        component={RouterLink}
                        to="/Register"
                        fullWidth
                        color="grey"
                        variant="contained"
                        sx={{ mb: 2 }}
                    >
                        S'inscrire
                    </Button>
                    <Button
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        onClick={() => navigate('/request-password-reset')}
                        sx={{ mb: 2 }}
                    >
                        Mot de passe oublié ?
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default Login;
