import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';
import { Box } from '@mui/system';

const RequestPasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleRequestReset = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');

    try {
      const response = await axios.post('/api/user/request-password-reset', { email });
      setMessage('Un e-mail de réinitialisation de mot de passe a été envoyé si l\'adresse est associée à un compte.');
    } catch (error) {
      setError('Une erreur s\'est produite. Veuillez réessayer.');
      console.error('Error requesting password reset:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Réinitialiser le mot de passe
        </Typography>
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <form onSubmit={handleRequestReset}>
          <TextField
            label="Adresse Email"
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Envoyer un e-mail de réinitialisation
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default RequestPasswordReset;
